/* You can add global styles to this file, and also import other style files */
.loginmail_input ngx-select-dropdown {
    width: 90px !important;
}
.loginmail_input ngx-select-dropdown .ngx-dropdown-container .ngx-dropdown-button {
    min-height: 46px !important;
    border-radius: 0 !important;
    width: 90px !important;
    z-index: 1 !important;
    // border: none !important;
}

.loginmail_input ngx-select-dropdown .ngx-dropdown-container .ngx-dropdown-list-container {
    width: 385px !important;
}

.ngx-dropdown-container[_ngcontent-iao-c48] button[_ngcontent-iao-c48] .nsdicon-angle-down[_ngcontent-iao-c48] {
   
    display: none;
}
.homevideo .webcam-wrapper{
    width: 100%;
    height: 100%;
}
.homevideo .webcam-wrapper video{
    width: 100%;
    height: 100%;
    aspect-ratio: unset;
    object-fit: cover;
}
@import '~@ctrl/ngx-emoji-mart/picker';
// @import '~ngx-sharebuttons/themes/default';

// #agora-video-player-track-86db4dd3{position: relative !important;}
// .agora_video_player{position: absolute !important;}

.emoji-mart.emoji-mart.ng-star-inserted {
    /* top: -60px; */
    position: absolute;
    top: -430px;
}
.welcome-block .form-group.floating>label {
    bottom: 41px;
    right: -14px;
    position: relative;
    background-color: white;
    padding: 3px 3px 0px 1px;
    font-size: 1.1em;
    transition: 0.1s;
    pointer-events: none;
    font-weight: 500 !important;
    transform-origin: bottom left;
    margin-bottom: 0px;
    color: #7E7E7E;
}

.iti{
    width: 100% !important;
}

time-picker{
    position: absolute;
    z-index: 999999;
}

.vjs-big-play-button{
    left: 0 !important;
    right: 0;
    top: 0 !important;
    bottom: 0;
    margin: auto;
    background-color: #ff0000 !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0;
    width: 80px !important;
    height: 50px !important;
    border-radius: 8px !important;
    line-height: 50px !important;
    color: #fff !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.vjs-default-skin .vjs-big-play-button:before{
    line-height: 1 !important;
    text-shadow: none !important;
    position: static;
    height: auto;
    width: auto;
    margin-left: 2px;
}
.vjs-default-skin.vjs-has-started .vjs-big-play-button{
    display: none !important;
}

